import { useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModalOneButton } from '../ButtonModalOneButton';
import { Host } from '../../../../Config';

export function AddGift({ state, changeState, filters }) {
	const [barcode, setBarcode] = useState('');

	return (
		<ButtonModalOneButton
			title="ارسال اشعار"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(barcode, filters);
			}}>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={barcode}
				placeholder="بارکود"
				onChange={(e) => setBarcode(e.target.value)}
				rows={3}
			/>
		</ButtonModalOneButton>
	);

	function insert(barcode, filters) {
		const data = {
			route: 'send_customers_gift',
			barcode: barcode,
			filters,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			toast.error('Please log in to send notifications.');
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		})
			.then((response) => {
				response.json().then((json) => {
					toast.success(json.status.message);
					changeState(false);
				});
			})
			.catch(() => {
				toast.error('حذث خطاء ما');
			});
	}
}
