import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModal } from '../../components/modals/ButtonModal';
import { CustomSelect } from '../../components/select/CustomSelect';
import { Host } from '../../Config';

export function AddBrandRule({ state, changeState, refresh }) {
	const [brand, setBrand] = useState(-1);
	const [percentage, setPercentage] = useState('');
	const [brands, setBrands] = useState([]);

	useEffect(() => {
		fetchBrands();
	}, []);

	return (
		<ButtonModal
			title="Add Brand Rule"
			btnText="Add"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(brand, percentage);
			}}>
			<div className="mb-3">
				<label className="form-label">Brand</label>
				<CustomSelect
					options={[{ value: 0, label: 'All Brands' }, ...brands]}
					handleChange={setBrand}
					defaultValue={brand}
				/>
			</div>

			<div className="mb-3">
				<label className="form-label">Percentage</label>
				<input
					type="number"
					className="form-control form-control-sm"
					value={percentage}
					placeholder="Percentage"
					onChange={(e) => {
						const value = parseInt(e.target.value);
						if (value >= 0 && value <= 100) {
							setPercentage(value);
						}
					}}
				/>
			</div>
		</ButtonModal>
	);

	function fetchBrands() {
		const data = {
			route: 'get_brands',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					setBrands(
						data.data.map((brand) => ({
							value: brand.id,
							label: brand.name,
						}))
					);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while fetching brands');
			});
	}

	function insert(brand, percentage) {
		const data = {
			route: 'add_brand_rule',
			brand: brand,
			percentage: percentage,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					toast.success(data.status.message);
					refresh();
					changeState(false);
				} else {
					toast.error(data.status?.message || 'An error occurred');
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while adding rule');
			});
	}
}
