import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModal } from '../../components/modals/ButtonModal';
import { CustomSelect } from '../../components/select/CustomSelect';
import { Host } from '../../Config';

export function AddBranchRule({ state, changeState, refresh }) {
	const [branch, setBranch] = useState('');
	const [brand, setBrand] = useState(-1);
	const [percentage, setPercentage] = useState('');
	const [branches, setBranches] = useState([{ value: 0, label: 'All Branches' }]);
	const [brands, setBrands] = useState([]); // Initialize with an empty array

	useEffect(() => {
		// fetchBranches();
		fetchBrands();
	}, []);

	return (
		<ButtonModal
			title="Add Branch Rule"
			btnText="Add"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(branch, brand, percentage);
				// console.log(branch, brand, percentage);
			}}>
			<div className="mb-3">
				<label className="form-label">Branch</label>
				<CustomSelect
					options={branches}
					handleChange={setBranch}
					defaultValue={branch}
				/>
			</div>

			<div className="mb-3">
				<label className="form-label">Brand</label>
				<CustomSelect
					options={[{ value: 0, label: 'All Brands' }, ...brands]}
					handleChange={setBrand}
					defaultValue={brand}
				/>
			</div>

			<div className="mb-3">
				<label className="form-label">Percentage</label>
				<input
					type="number"
					className="form-control form-control-sm"
					value={percentage}
					placeholder="Percentage"
					onChange={(e) => {
						const value = parseInt(e.target.value);
						if (value >= 0 && value <= 100) {
							setPercentage(value);
						}
					}}
				/>
			</div>
		</ButtonModal>
	);

	async function fetchBrands() {
		setBrands([]); // Set to an empty array instead of null
		const data = {
			route: 'fetch_brands',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		var response = await fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		});

		var brands = await response.json();
		if (brands.status.code === 1) {
			const newData = [];
			brands.data.forEach((e) => {
				newData.push({ value: e.id, label: e.en_name });
			});
			setBrands(newData);
		} else {
			setBrands([]);
		}
	}

	function insert(branch, brand, percentage) {
		const data = {
			route: 'add_branch_rule',
			branch: branch,
			brand: brand,
			percentage: percentage,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					toast.success(data.status.message);
					refresh();
					changeState(false);
				} else {
					toast.error(data.status?.message || 'An error occurred');
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while adding rule');
			});
	}
}
