import { useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModalOneButton } from '../ButtonModalOneButton';
import { Host } from '../../../../Config';
import { CustomSelect } from '../CustomSelect';

export function AddBonuses({ state, changeState, filters, brands }) {
	const [brand, setBrand] = useState({ value: 0, label: `All brands` });
	const [percentage, setPercentage] = useState('');
	const [expireDate, setExpireDate] = useState('');
	const [barcodes, setBarcodes] = useState('');

	return (
		<ButtonModalOneButton
			title="Add Bonuses"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(brand, percentage, expireDate, barcodes, filters);
			}}>
			<div className="my-2 form-group">
				<label htmlFor="percentage">Percentage</label>
				<input
					type="number"
					id="percentage"
					name="percentage"
					min="0"
					max="100"
					className="form-control"
					value={percentage}
					onChange={(e) => {
						if (e.target.value > 100) {
							setPercentage(100);
						} else if (e.target.value < 0) {
							setPercentage(0);
						} else {
							setPercentage(e.target.value);
						}
					}}
				/>
			</div>
			<div className="my-2 form-group">
				<label htmlFor="expireDate">Expire Date</label>
				<input
					type="datetime-local"
					id="expireDate"
					name="expireDate"
					className="form-control"
					value={expireDate}
					onChange={(e) => setExpireDate(e.target.value)}
				/>
			</div>
			<div className="my-2 form-group">
				<label htmlFor="barcodes">Barcodes</label>
				<textarea
					id="barcodes"
					name="barcodes"
					className="form-control"
					rows="3"
					value={barcodes}
					onChange={(e) => {
						setBarcodes(e.target.value);
						setBrand({ value: 0, label: `All brands` });
					}}
					disabled={brand.value !== 0 ? true : false}></textarea>
			</div>
			<div className="my-2 form-group">
				<label htmlFor="brand">Brand</label>
				<CustomSelect
					id="brand"
					options={brands}
					defaultValue={{ value: 0, label: `All brands` }}
					value={brand}
					onChange={(selectedOption) => {
						setBrand(selectedOption);
						setBarcodes('');
					}}
					disabled={barcodes ? false : true}
				/>
			</div>
		</ButtonModalOneButton>
	);

	function insert(brand, percentage, expireDate, barcodes, filters) {
		console.log('inserting bonuses');
		console.log('brand:', brand);
		console.log('percentage:', percentage);
		console.log('expireDate:', expireDate);
		console.log('barcodes:', barcodes);
		console.log('filters:', filters);
		const data = {
			route: 'add_bonuses_filter',
			filters: filters,
			brand: brand.value,
			percentage: percentage,
			expire_date: expireDate,
			barcodes: barcodes,
		};
		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');
		fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		})
			.then((response) => {
				response.json().then((json) => {
					toast.success(json.status.message);
					changeState(false);
				});
			})
			.catch(() => {
				toast.error('حذث خطاء ما');
			});
	}
}
