import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModal } from '../../components/modals/ButtonModal';
import { CustomSelect } from '../../components/select/CustomSelect';
import { Host } from '../../Config';

export function EditBranchRule({ state, changeState, refresh, item }) {
	const [branch, setBranch] = useState(item.branch);
	const [brand, setBrand] = useState(item.brand);
	const [percentage, setPercentage] = useState(item.percentage);
	const [branches, setBranches] = useState([]);
	const [brands, setBrands] = useState([]);

	useEffect(() => {
		fetchBranches();
		fetchBrands();
	}, []);

	useEffect(() => {
		setBranch(item.branch);
		setBrand(item.brand);
		setPercentage(item.percentage);
	}, [item]);

	return (
		<ButtonModal
			title="Edit Branch Rule"
			btnText="Save"
			state={state}
			changeState={changeState}
			btnAction={() => {
				update(item.id, branch, brand, percentage);
			}}>
			<div className="mb-3">
				<label className="form-label">Branch</label>
				<CustomSelect
					options={branches}
					handleChange={setBranch}
					defaultValue={branch}
				/>
			</div>

			<div className="mb-3">
				<label className="form-label">Brand</label>
				<CustomSelect
					options={[{ value: 0, label: 'All Brands' }, ...brands]}
					handleChange={setBrand}
					defaultValue={brand}
				/>
			</div>

			<div className="mb-3">
				<label className="form-label">Percentage</label>
				<input
					type="number"
					className="form-control form-control-sm"
					value={percentage}
					placeholder="Percentage"
					onChange={(e) => {
						const value = parseInt(e.target.value);
						if (value >= 0 && value <= 100) {
							setPercentage(value);
						}
					}}
				/>
			</div>
		</ButtonModal>
	);

	function fetchBranches() {
		const data = {
			route: 'get_branches',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					setBranches(
						data.data.map((branch) => ({
							value: branch.id,
							label: branch.name,
						}))
					);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while fetching branches');
			});
	}

	function fetchBrands() {
		const data = {
			route: 'get_brands',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					setBrands(
						data.data.map((brand) => ({
							value: brand.id,
							label: brand.name,
						}))
					);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while fetching brands');
			});
	}

	function update(id, branch, brand, percentage) {
		const data = {
			route: 'update_branch_rule',
			id: id,
			branch: branch,
			brand: brand,
			percentage: percentage,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					toast.success(data.status.message);
					refresh();
					changeState(false);
				} else {
					toast.error(data.status?.message || 'An error occurred');
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while updating rule');
			});
	}
}
