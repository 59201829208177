import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { DataTable } from '../../components/datatable/Datatable';
import { Host } from '../../Config';
import { AddBrandRule } from './AddBrandRule';
import { EditBrandRule } from './EditBrandRule';

export function BrandRules() {
	const [addState, setAddState] = useState(false);
	const [editState, setEditState] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	const headers = [
		{ title: '#', prop: 'id', isFilterable: true },
		{ title: 'Brand', prop: 'brand', isFilterable: true },
		{ title: 'Percentage', prop: 'percentage', isFilterable: true },
		{
			title: 'Status',
			prop: 'is_enabled',
			isFilterable: true,
			cell: (row) => <span className={`badge bg-${row.is_enabled ? 'success' : 'danger'}`}>{row.is_enabled ? 'Enabled' : 'Disabled'}</span>,
		},
		{ title: 'Enabled At', prop: 'enabled_at', isFilterable: true },
		{ title: 'Disabled At', prop: 'disabled_at', isFilterable: true },
		{
			title: 'Actions',
			prop: 'actions',
			cell: (row) => (
				<div className="btn-group btn-group-sm">
					<button
						type="button"
						className="btn btn-dark"
						onClick={() => {
							setEditState(true);
							setSelectedItem(row);
						}}>
						<i className="bi bi-pencil"></i>
					</button>
					<button
						type="button"
						className={`btn btn-${row.is_enabled ? 'danger' : 'success'}`}
						onClick={() => {
							toggleStatus(row.id, !row.is_enabled);
						}}>
						<i className={`bi bi-${row.is_enabled ? 'x-circle' : 'check-circle'}`}></i>
					</button>
				</div>
			),
		},
	];

	const [data, setData] = useState(null);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			{data ? (
				<>
					<div className="pt-3 pe-3 ps-3 pb-3">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col fw-bold">Brand Rules</div>
									<div
										className="col"
										align="right">
										<button
											className="btn btn-sm btn-dark"
											onClick={() => {
												setAddState(true);
											}}>
											<i className="bi bi-plus"></i> Add Rule
										</button>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="">
									<DataTable
										body={data}
										headers={headers}
									/>
								</div>
							</div>
						</div>
					</div>

					<AddBrandRule
						state={addState}
						changeState={setAddState}
						refresh={() => {
							fetchData();
						}}
					/>
					{selectedItem && (
						<EditBrandRule
							state={editState}
							changeState={setEditState}
							refresh={() => {
								fetchData();
							}}
							item={selectedItem}
						/>
					)}
				</>
			) : (
				<div
					className="w-100 d-flex justify-content-center align-items-center"
					id="spinner">
					<Spinner />
				</div>
			)}

			<Toaster position="top-center" />
		</>
	);

	function fetchData() {
		const data = {
			route: 'fetch_brand_rules',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					setData(data.data);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while fetching data');
			});
	}

	function toggleStatus(id, newStatus) {
		const data = {
			route: 'toggle_brand_rule',
			id: id,
			status: newStatus,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}

		const token = localStorage.getItem('token');

		fetch(Host, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status?.status === true) {
					toast.success(data.status.message);
					fetchData();
				} else {
					toast.error(data.status?.message || 'An error occurred');
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				toast.error('An error occurred while updating status');
			});
	}
}
